.box.hsbc {
  background-color: #4d6474;
  color: white;
}

.box.hsbc .header {
  border: 1px solid #4d6474;
}

.certificate-group-box-header.hsbc {
  background-color: #4d6474;
  color: white;
  font-size: 18px;
  padding: 2px 2px 2px 2px;
}

.certificate-group-box-footer.hsbc {
  background-color: #4d6474;
}

.xact-button.hsbc {
  text-align: center;
  vertical-align: middle;
  background-color: white;
  color: #4d6474;
  border: none;
  min-width: 80px;
  padding: 0;
  margin: 0;
  text-decoration: none !important;
}

.xact-button.hsbc:hover {
  background-color: #db0011;
  color: #ffffff;
  cursor: pointer;
}

.input-field-readonly.hsbc {
  background: #d7d8d6;
  color: #333333;
  border: 0 none;
  font-family: 'Source Sans Pro Regular', verdana, helvetica, sans-serif;
  font-size: 15px;
  height: 28px;
  padding: 3px;
}
