@font-face {
  font-family: 'Source Sans Pro';
  src:
    url('../fonts/SourceSansProSemiBold.eot') format('eot'),
    url('../fonts/SourceSansProSemiBold.woff') format('woff');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Source Sans Pro Regular';
  src:
    url('../fonts/SourceSansProRegular.eot') format('eot'),
    url('../fonts/SourceSansProRegular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: DINWeb-Light;
  src:
    url('../fonts/DINWeb-Light.eot') format('eot'),
    url('../fonts/DINWeb-Light.woff') format('woff');
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

#page-header {
  background-color: rgba(255, 255, 255, 0.5);
  height: 95px;
  width: 100%;
  padding: 10px;
  z-index: -1 !important;
}

.box {
  display: flex;
  flex-flow: column;
  width: 500px;
  min-height: 500px;
  font-family: 'Source Sans Pro', verdana, helvetica, sans-serif;
  font-size: 15px;
  height: 0;
}

.box .header {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  background-color: white;
  padding: 25px;
  color: black;
  line-height: 1.2;
  font-size: 26pt;
  font-family: DINWeb-Light, verdana, helvetica, sans-serif;
}

.box.header span {
  font-size: 26pt;
  font-family: DINWeb-Light, verdana, helvetica, sans-serif;
}

.box .content {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  flex-direction: column;
  padding-top: 13px;
  padding-right: 25px;
  padding-left: 25px;
}

.box .footer {
  padding-right: 25px;
  padding-left: 25px;
  padding-bottom: 25px;
  padding-top: 10px;
}

.box .no-bottom-padding {
  padding-bottom: 0px;
}

.input-field {
  background: #ffffff;
  color: #333333;
  border: 0 none;
  font-family: 'Source Sans Pro Regular', verdana, helvetica, sans-serif;
  font-size: 15px;
  height: 28px;
  padding: 3px;
}

.input-field.small {
  height: 23px;
  padding: 2px;
  font-size: 14px;
}

.footer-text {
  color: #ffffff;
}

.loader-spinner {
  width: 3rem !important;
  height: 3rem !important;
}

.alert {
  font-size: smaller;
  padding: 0.25rem !important;
}

.alert-primary {
  background-color: #10778a;
  border-color: #10778a;
  color: #ffffff;
  border-radius: 0;
}

.alert-warning {
  background-color: #ff9933;
  border-color: #ff9933;
  color: #ffffff;
  border-radius: 0;
}

.alert-danger {
  background-color: #cc3333;
  border-color: #cc3333;
  color: #ffffff;
  border-radius: 0;
}

.alert-success {
  background-color: #66cc33;
  border-color: #66cc33;
  color: #ffffff;
  border-radius: 0;
}

.line-break {
  white-space: pre-line;
}

.dropdown,
.dropdown-link {
  color: #d3d3d3 !important;
  font-style: italic;
  font-size: 12px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  padding: 0.5rem 0;
  z-index: 1;
  list-style: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.xact-button.big {
  min-width: 120px !important;
  padding: 2px !important;
}

.xact-button.disabled {
  background-color: lightgray !important;
  color: darkgray !important;
}

.xact-button.disabled:hover {
  background-color: lightgray !important;
  color: darkgray !important;
  cursor: default !important;
}
