#print-container {
  font-size: 13px;
  visibility: hidden;
}

#certificates-table {
  border: 1px solid black;
  font-size: 13px;
  width: 100%;
}

#section-to-print {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

@media print {
  body * {
    visibility: hidden;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
    color: black;
  }

  #section-to-print {
    position: absolute;
    left: 0;
    top: 2cm;
  }
}
