.box.cfcl {
  background-color: #00809e;
  color: white;
}

.box.cfcl .header {
  border: 1px solid #00809e;
}

.certificate-group-box-header.cfcl {
  background-color: #00809e;
  color: white;
  font-size: 18px;
  padding: 2px 2px 2px 2px;
}

.certificate-group-box-footer.cfcl {
  background-color: #00809e;
}

.xact-button.cfcl {
  text-align: center;
  vertical-align: middle;
  background-color: white;
  color: #00809e;
  border: none;
  min-width: 80px;
  padding: 0;
  margin: 0;
  text-decoration: none !important;
}

.xact-button.cfcl:hover {
  background-color: #004860;
  color: #ffffff;
  cursor: pointer;
}

.input-field-readonly.cfcl {
  background: #b2e4ec;
  color: #333333;
  border: 0 none;
  font-family: 'Source Sans Pro Regular', verdana, helvetica, sans-serif;
  font-size: 15px;
  height: 28px;
  padding: 3px;
}
