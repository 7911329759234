table {
  table-layout: fixed !important;
  margin-top: 10px !important;
  border-collapse: separate !important;
  border-spacing: 5px !important;
}

.icon-column {
  width: 35px;
}

.w-30 {
  width: 30%;
}

.flex-auto {
  flex: auto;
}
