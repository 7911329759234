.display-box-landing-page {
  min-width: 500px;
  min-height: 400px;
  width: 100%;
  background-color: #00809e;
}

.button-row {
  width: 100%;
  height: 60px;
  padding: 35px 25px 25px;
}
