.box.strate {
  background-color: #e3e1e2;
  color: #353535;
}

.box.strate .header {
  border: 1px solid #e3e1e2;
}

.certificate-group-box-header.strate {
  background-color: #e3e1e2;
  color: #353535;
  font-size: 18px;
  padding: 2px 2px 2px 2px;
}

.certificate-group-box-footer.strate {
  background-color: #e3e1e2;
}

.xact-button.strate {
  text-align: center;
  vertical-align: middle;
  background-color: white;
  color: #353535;
  border: none;
  min-width: 80px;
  padding: 0;
  margin: 0;
  text-decoration: none !important;
}

.xact-button.strate:hover {
  background-color: #562c8e;
  color: #ffffff;
  cursor: pointer;
}

.input-field-readonly.strate {
  background: #7e8287;
  color: #000000;
  border: 0 none;
  font-family: 'Source Sans Pro Regular', verdana, helvetica, sans-serif;
  font-size: 15px;
  height: 28px;
  padding: 3px;
}
