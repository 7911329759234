.box.tmx {
  background-color: #00a5c0;
  color: white;
}

.box.tmx .header {
  border: 1px solid #00a5c0;
}

.box.tmx .header > div {
  padding-left: 16px;
}

.certificate-group-box-header.tmx {
  background-color: #00a5c0;
  color: white;
  font-size: 18px;
  padding: 2px 2px 2px 2px;
}

.certificate-group-box-footer.tmx {
  background-color: #00a5c0;
}

.xact-button.tmx {
  text-align: center;
  vertical-align: middle;
  background-color: white;
  color: #00a5c0;
  border: none;
  min-width: 80px;
  padding: 0;
  margin: 0;
  text-decoration: none !important;
}

.xact-button.tmx:hover {
  background-color: #66cc33;
  color: #ffffff;
  cursor: pointer;
}

.input-field-readonly.tmx {
  background: #b2e4ec;
  color: #333333;
  border: 0 none;
  font-family: 'Source Sans Pro Regular', verdana, helvetica, sans-serif;
  font-size: 15px;
  height: 28px;
  padding: 3px;
}
