.certificate-list-box {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  overflow-y: scroll;
  height: 100%;
  background-color: white;
}

.certificate {
  align-items: center;
  font-size: 12px;
  line-height: normal;
  padding: 0 !important;
}

.certificate label {
  font-weight: bolder;
}

.list-group-item {
  color: black !important;
}

.list-group-item.active {
  background-color: #d3f8d3 !important;
  border-color: rgba(0, 0, 0, 0.125) !important;
}

.h-24px {
  height: 24px !important;
}

.round-button {
  min-width: 24px !important;
  width: 24px !important;
  height: 24px !important;
  font-weight: bolder !important;
  border-radius: 50% !important;
}

div.danger {
  color: red !important;
  font-weight: bold;
}

div.warning {
  color: darkorange !important;
  font-weight: bold;
}
