.box.support {
  width: 1260px;
  height: 800px;
}

#support-container {
  height: 550px;
  width: 1200px;
  margin: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  color: #8c8181;
  background-color: #efe0e0;
  padding: 10px;
  font-family: 'Source Sans Pro Regular', verdana, helvetica, sans-serif;
  font-size: 15px;
}

#support-container.active {
  color: #000000;
  background-color: #ffffff;
}

.certificate-group-box {
  border: 2px solid #8c8181;
  color: black;
  width: 560px;
  float: left;
  margin: 10px;
  font-size: 11px;
  border-collapse: collapse;
}

.certificate-group-box-footer {
  padding: 2px 2px 2px 2px;
  font-size: 14px;
}

.OK {
  color: green;
  margin-left: 5px;
}

.WARNING {
  color: orange;
  margin-left: 5px;
}

.FAILURE {
  color: red;
  margin-left: 5px;
}

.INFO {
  color: teal;
  margin-left: 5px;
}

.RUNNING {
  color: blue;
  margin-left: 5px;
}

.child-check {
  margin-left: 20px;
}

.bg-pink {
  background-color: #f3c6b4;
}

.user-info-icon {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 40px;
}

.user-info-icon::after,
.user-info-icon::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  border-radius: 3px;
  width: 2px;
  background: #fff;
  left: 7px;
}

.user-info-icon::after {
  bottom: 2px;
  height: 8px;
}

.user-info-icon::before {
  height: 2px;
  top: 2px;
}

.user-info-btn {
  width: 44px;
}

.btn-info:hover,
.btn-info:active,
.btn-info:focus {
  background-color: #17a2b8;
  border-color: #17a2b8;
  transition: none;
  box-shadow: none;
}
